import React from "react";
import Local from "../../../assets/icons/Local.svg";
import "./alertLocal.scss";
import { useTranslation } from "react-i18next";

export default function Alert({ from }) {
  const { t } = useTranslation();
  const alertMessage = t("alert.ALERT_LOCALISQTION");

  return (
    <div
      className={
        from === "home"
          ? "alert-home"
          : from === "mosque"
          ? "alert-mosque"
          : "alert-prayer"
      }
    >
      <img src={Local} alt="Location icon" />
      <p>{alertMessage}</p>
    </div>
  );
}
