import "./AboutUsPage.scss";
import { useTranslation } from "react-i18next";
import Footer from "../../components/layout/Footer";
import { Link } from "react-router-dom";

const AboutUsPage = () => {
  const { t } = useTranslation();
  return (
    <div className="pages-position">
      <div className="pages-header">
        <span className="title-page">{t("about.TITLE")}</span>
      </div>
      <div className="about-body">
        <p className="intro-description">
          {t("about.introduction.DESCRIPTION")}
        </p>

        {/* Mission */}
        <section className="section-container">
          <h2 className="section-title">{t("about.Mission.SECTION")}</h2>
          <p>{t("about.Mission.DESCRIPTION")}</p>
          <p>{t("about.Mission.TEXT")}</p>

          <ul className="section-list">
            <li>{t("about.Mission.ITEM_1")}</li>
            <li>{t("about.Mission.ITEM_2")}</li>
            <li>{t("about.Mission.ITEM_3")}</li>
          </ul>
        </section>

        {/* Objectifs */}
        <section className="section-container">
          <h2 className="section-title">{t("about.objectifs.SECTION")}</h2>
          <p>{t("about.objectifs.DESCRIPTION")}</p>
          <h3>{t("about.objectifs.SECTION_2")}</h3>
          <ul className="section-list">
            <li>{t("about.objectifs.ITEM_1")}</li>
            <li>{t("about.objectifs.ITEM_2")}</li>
            <li>{t("about.objectifs.ITEM_3")}</li>
          </ul>
        </section>

        {/* Valeurs */}
        <section className="section-container">
          <h2 className="section-title">{t("about.values.SECTION")}</h2>
          <p>{t("about.values.DESCRIPTION")}</p>
          <ul className="section-list">
            <li>{t("about.values.ITEM_1")}</li>
            <li>{t("about.values.ITEM_2")}</li>
            <li>{t("about.values.ITEM_3")}</li>
            <li>{t("about.values.ITEM_4")}</li>
          </ul>
        </section>

        {/* Services 1 */}
        <section className="section-container">
          <h2 className="section-title">{t("about.SERVICE.SECTION")}</h2>
          <p>{t("about.SERVICE.DESCRIPTION")}</p>

          {/* Charia et Fondements Islamiques */}
          <div className="service-item">
            <h3 className="service-title">
              {t("about.SERVICE.SERVICE_1.TITLE")}
            </h3>
            <p>{t("about.SERVICE.SERVICE_1.DESCRIPTION")}</p>
            <div className="service-details">
              {/* SHAHADA */}

              <p>{t("about.SERVICE.SERVICE_1.TEXT")}</p>
              <ul className="service-list">
                <h3>{t("about.SERVICE.SERVICE_1.PILARS_ISLAM.TITLE")}</h3>
                <li>
                  <span>
                    {t("about.SERVICE.SERVICE_1.PILARS_ISLAM.VALUES_1.TITLE")}
                    {t(
                      "about.SERVICE.SERVICE_1.PILARS_ISLAM.VALUES_1.DESCRIPTION"
                    )}
                  </span>
                </li>
                {/* SALAT */}

                <li>
                  <span>
                    <Link to="/prayer-times">
                      {t("about.SERVICE.SERVICE_1.PILARS_ISLAM.VALUES_2.TITLE")}
                    </Link>
                    {t(
                      "about.SERVICE.SERVICE_1.PILARS_ISLAM.VALUES_2.DESCRIPTION"
                    )}
                  </span>
                </li>
                {/* ZAKAT */}

                <li>
                  <span>
                    <Link to="/zakat-calculator">
                      {t("about.SERVICE.SERVICE_1.PILARS_ISLAM.VALUES_3.TITLE")}
                    </Link>
                    {t(
                      "about.SERVICE.SERVICE_1.PILARS_ISLAM.VALUES_3.DESCRIPTION"
                    )}
                  </span>
                </li>
                {/* SAWM */}

                <li>
                  <Link to="/dhikr/Food___Drink">
                    {t("about.SERVICE.SERVICE_1.PILARS_ISLAM.VALUES_4.TITLE")}
                  </Link>
                  {t(
                    "about.SERVICE.SERVICE_1.PILARS_ISLAM.VALUES_4.DESCRIPTION"
                  )}
                </li>
                {/* Hajj */}
                <li>
                  <Link to="/dhikr/Hajj___Umrah">
                    {t("about.SERVICE.SERVICE_1.PILARS_ISLAM.VALUES_5.TITLE")}
                  </Link>
                  {t(
                    "about.SERVICE.SERVICE_1.PILARS_ISLAM.VALUES_5.DESCRIPTION"
                  )}
                </li>
              </ul>
            </div>
          </div>

          {/* Service 2: Fiqh et Jurisprudence */}
          <div className="service-item">
            <h3 className="service-title">
              {t("about.SERVICE.SERVICE_2.TITLE")}
            </h3>
            <p>{t("about.SERVICE.SERVICE_2.DESCRIPTION")}</p>
            <div className="service-details">
              <p>{t("about.SERVICE.SERVICE_2.TEXT")}</p>
              <ul className="service-list">
                <h3>{t("about.SERVICE.SERVICE_2.FIQH_ACTES.TITLE")}</h3>

                {/* PURICATION */}
                <li>
                  <span>
                    <Link to="/learn/wudhu&ghousl">
                      {t("about.SERVICE.SERVICE_2.FIQH_ACTES.VALUES_1.TITLE")}
                    </Link>
                    {t(
                      "about.SERVICE.SERVICE_2.FIQH_ACTES.VALUES_1.DESCRIPTION"
                    )}
                  </span>
                </li>

                {/* SALAT */}
                <li>
                  <span>
                    <Link to="/learn/salat">
                      {t("about.SERVICE.SERVICE_2.FIQH_ACTES.VALUES_2.TITLE")}
                    </Link>
                    {t(
                      "about.SERVICE.SERVICE_2.FIQH_ACTES.VALUES_2.DESCRIPTION"
                    )}
                    <Link to="/mosque">
                      {t(
                        "about.SERVICE.SERVICE_2.FIQH_ACTES.VALUES_2.DESCRIPTION_2"
                      )}
                    </Link>
                    {t(
                      "about.SERVICE.SERVICE_2.FIQH_ACTES.VALUES_2.DESCRIPTION_3"
                    )}
                  </span>
                </li>
              </ul>

              {/*Fiqh des Transactions*/}

              <ul className="service-list">
                <h3>{t("about.SERVICE.SERVICE_2.FIQH_TRANSACTIONS.TITLE")}</h3>
                <li>
                  <span>
                    {t("about.SERVICE.SERVICE_2.FIQH_TRANSACTIONS.VALUES_1")}
                  </span>
                </li>
                <li>
                  <span>
                    {t("about.SERVICE.SERVICE_2.FIQH_TRANSACTIONS.VALUES_2")}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          {/* Service 3: Enseignements Islamiques */}
          <div className="service-item">
            <h3 className="service-title">
              {t("about.SERVICE.SERVICE_3.TITLE")}
            </h3>
            <p>{t("about.SERVICE.SERVICE_3.DESCRIPTION")}</p>
            <ul className="service-list">
              <li>
                <span>
                  <Link to="/quran">
                    {t("about.SERVICE.SERVICE_3.VALUES_1.TITLE")}
                  </Link>
                  {t("about.SERVICE.SERVICE_3.VALUES_1.DESCRIPTION")}
                </span>
              </li>
              <li>
                <span>
                  <Link to="/prophets-stories">
                    {t("about.SERVICE.SERVICE_3.VALUES_2.TITLE")}
                  </Link>
                  {t("about.SERVICE.SERVICE_3.VALUES_2.DESCRIPTION")}
                </span>
              </li>
              {/*hadiths*/}
              <li>
                <span>
                  <Link to="/hadiths-prophet">
                    {t("about.SERVICE.SERVICE_3.VALUES_3.TITLE")}
                  </Link>
                  {t("about.SERVICE.SERVICE_3.VALUES_3.DESCRIPTION")}
                </span>
              </li>
              {/*Noms d’Allah */}
              <li>
                <span>
                  <Link to="/names-of-allah">
                    {t("about.SERVICE.SERVICE_3.VALUES_4.TITLE")}
                  </Link>
                  {t("about.SERVICE.SERVICE_3.VALUES_4.DESCRIPTION")}
                </span>
              </li>
              {/*Dhikr et Dua*/}
              <li>
                <span>
                  <Link to="/dhikr">
                    {t("about.SERVICE.SERVICE_3.VALUES_5.TITLE")}{" "}
                  </Link>
                  {t("about.SERVICE.SERVICE_3.VALUES_5.DESCRIPTION")}
                </span>
              </li>
            </ul>
          </div>
        </section>

        {/* Rejoindre la communauté */}
        <section className="join-container">
          <h2>{t("about.JOIN.TITLE")}</h2>
          <p>{t("about.JOIN.DESCRIPTION")}</p>
          <p>{t("about.JOIN.TEXT")}</p>
          <ul className="join-list">
            <li>{t("about.JOIN.ITEM_1")}</li>
            <li>{t("about.JOIN.ITEM_2")}</li>
          </ul>
          <p>
            {t("about.JOIN.CONTACT")}
            <Link to="/Contact">{t("about.JOIN.CONTACT_LINK")}</Link>
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};
export default AboutUsPage;
