import React from "react";
import { useTranslation } from "react-i18next";
import "./PrayerTimesPage.scss";
import salatImage from "../../assets/images/salat.svg";
import mosqueImage from "../../assets/images/mosque.svg";
import fajrImage from "../../assets/logos/prayer/fajr.png";
import sunriseImage from "../../assets/logos/prayer/sunrise.png";
import dhuhrImage from "../../assets/logos/prayer/dhuhr.png";
import asrImage from "../../assets/logos/prayer/asr.png";
import maghribImage from "../../assets/logos/prayer/maghrib.png";
import ishaImage from "../../assets/logos/prayer/isha.png";
import { useAppDispatch, useAppSelector } from "../../app/hooks.ts";
import { MyCalendar } from "../../components/common/calendar/MyCalendar.tsx";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import Dialog from "../../components/common/dialog/Dialog.tsx";
import { openDialog } from "../../features/general/generalSlice.ts";
import Footer from "../../components/layout/Footer.jsx";
import Alert from "../../components/common/Alert/alertLocal.jsx";

const PrayerTimesPage = () => {
  const { t, i18n } = useTranslation();
  const prayerTimes = useAppSelector((state) => state.prayerTime);
  const [nextPrayer, setNextPrayer] = useState<{
    name: string;
    time: string;
  } | null>(null);
  const [timeLeft, setTimeLeft] = useState<string | null>(null);
  const intervalRef = useRef<any>(null); // Use useRef to store the interval ID
  const [hijriCalendar, setHijriCalendar] = useState<boolean>(false);
  const positionError = useAppSelector(
    (state) => state.localisationReducer.error
  );
  const dialog = useAppSelector((state) => state.generalSlice);
  const dispatch = useAppDispatch();

  const PRAYER_LIST = [
    { name: "Fajr", image: fajrImage },
    { name: "Sunrise", image: sunriseImage },
    { name: "Dhuhr", image: dhuhrImage },
    { name: "Asr", image: asrImage },
    { name: "Maghrib", image: maghribImage },
    { name: "Isha", image: ishaImage },
  ];

  const setDataNextPrayer = () => {
    const currentTime = moment().format("HH:mm");
    for (
      let salatIndex = 0;
      salatIndex < PRAYER_LIST.length - 1;
      salatIndex++
    ) {
      if (
        moment(currentTime, "HH:mm").isBetween(
          moment(prayerTimes.times[PRAYER_LIST[salatIndex].name], "HH:mm"),
          moment(prayerTimes.times[PRAYER_LIST[salatIndex + 1].name], "HH:mm")
        )
      ) {
        setNextPrayer({
          name: PRAYER_LIST[salatIndex + 1].name,
          time: prayerTimes.times[PRAYER_LIST[salatIndex + 1].name],
        });
        const secondsLeft = moment(
          prayerTimes.times[PRAYER_LIST[salatIndex + 1].name],
          "HH:mm"
        ).diff(moment(), "seconds");

        setTimeLeft(moment.utc(secondsLeft * 1000).format("HH:mm:ss"));

        intervalRef.current = setInterval(() => {
          setTimeLeft((currentValue) =>
            moment(currentValue, "HH:mm:ss")
              .subtract(1, "seconds")
              .format("HH:mm:ss")
          );
        }, 1000);
        return;
      }
    }
  };

  useEffect(() => {
    if (
      prayerTimes.date?.gregorian.date === moment().format("DD-MM-YYYY") &&
      !intervalRef.current
    ) {
      setDataNextPrayer();
    }
  }, [prayerTimes.date?.gregorian.date]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (timeLeft === "00:00:00") {
      clearInterval(intervalRef.current);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (positionError) {
      dispatch(
        openDialog({
          nameDialog: "errorLocalisation",
          title: "alert.ADD_ACCESS",
          description: "alert.POSITION_REQUIRED",
        })
      );
    }
  }, [positionError]);

  return dialog.dialogOpened && dialog.nameDialog === "errorLocalisation" ? (
    <Dialog
      primaryButton={null}
      secondaryButton={{ title: t("common.OK") }}
    ></Dialog>
  ) : (
    <div>
      <div className="pages-position">
        <div className="prayer-times-header">
          <img src={salatImage} alt="" />
        </div>
        <div className="prayer-times-body">
          <div className="calendar-container">
            <div className="type-calendar-container">
              <div
                style={{
                  borderRadius:
                    i18n.language === "ar"
                      ? "0px 10px 10px 0px"
                      : "10px 0 0 10px",
                }}
                className={
                  "type-calendar " + (!hijriCalendar ? "selected-type" : "")
                }
                onClick={() => setHijriCalendar(false)}
              >
                <span className="type">{t("prayer.MILADI")}</span>
              </div>
              <div
                className={
                  "type-calendar " + (hijriCalendar ? "selected-type" : "")
                }
                style={{
                  borderRadius:
                    i18n.language === "ar"
                      ? "10px 0 0 10px"
                      : "0px 10px 10px 0px",
                }}
                onClick={() => setHijriCalendar(true)}
              >
                <span className="type">{t("prayer.HIJRI")}</span>
              </div>
            </div>
            <MyCalendar hijriCalendar={hijriCalendar} />
          </div>
          <div className="prayer-times-container">
            {nextPrayer ? (
              <div className="next-prayer">
                <div className="next-prayer-times">
                  <span className="text-xl">
                    {t("prayer.NEXT_PRAYER")}
                    <span className="text-golden font-semibold">
                      {t(`prayer.${nextPrayer.name}`)}
                    </span>
                  </span>
                  <span
                    className="time-left"
                    style={{
                      color: timeLeft === "00:00:00" ? "var(--red)" : "",
                    }}
                  >
                    {timeLeft}
                  </span>
                  <span className="text-green font-semibold text-xl">
                    {nextPrayer.time}
                  </span>
                </div>
                <img src={mosqueImage} alt="" />
              </div>
            ) : (
              <Alert from="prayer-times-page" />
            )}

            <div className="list-prayers">
              {PRAYER_LIST.map((prayer, index) => (
                <CardPrayer
                  prayer={prayer}
                  prayerTimes={prayerTimes}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const CardPrayer = ({ prayer, prayerTimes }) => {
  const { t } = useTranslation();

  return (
    <div className="card-prayer">
      <span className="">{prayerTimes.times[prayer.name]}</span>
      <img src={prayer.image} alt={"image" + prayer.name} />
      <span style={{ textAlign: "center" }}>{t("prayer." + prayer.name)}</span>
    </div>
  );
};

export default PrayerTimesPage;
