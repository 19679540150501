import video404 from "../../assets/videos/404.mp4";

const Error404 = () => {
  return (
    <div className="absolute flex items-center justify-center w-screen">
      <div className="mt-32 w-4/5 md:w-1/2">
        <video autoPlay muted loop id="video-home">
          <source src={video404} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
    </div>
  );
};
export default Error404;
