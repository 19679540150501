import { useAppSelector } from "../../../app/hooks.ts";
import { useTranslation } from "react-i18next";
import { PRAYER_LIST } from "../../../constants/static_data.constants.ts";
import "./PrayerTimes.scss";
import { useNavigate } from "react-router-dom";
import { Language } from "../../../enums/Language.ts";
import Alert from "../../common/Alert/alertLocal.jsx";

const PrayerTimes = () => {
  const prayerTimes = useAppSelector((state) => state.prayerTime);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="prayer-times flex justify-between mb-2">
      <div className="prayer-date">
        <div className="flex items-center">
          <h1>{t("prayer.PRAYER_TIMES")}</h1>

          <svg
            onClick={() => {
              navigate("/prayer-times");
            }}
            className="h-8 w-8 text-greenCalendar cursor-pointer ml-3"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" y1="8" x2="12" y2="16" />
            <line x1="8" y1="12" x2="16" y2="12" />
          </svg>
        </div>

        <span style={{ fontSize: "0.9em" }}>
          {prayerTimes?.date &&
            `${new Intl.DateTimeFormat(`${i18n.language}-TN-u-ca-islamic`, {
              day: "numeric",
              month: "long",
              weekday: "long",
              year: "numeric",
            }).format(new Date())} - ${new Intl.DateTimeFormat(
              `${i18n.language}-TN-u-ca`,
              {
                day: "numeric",
                month: "long",
                year: "numeric",
              }
            ).format(new Date())}`}
        </span>
      </div>

      {!prayerTimes?.date ? (
        <Alert from="home" />
      ) : (
        <div className="display-times">
          <div className="flex items-end">
            {PRAYER_LIST.map((prayer) => (
              <div key={prayer} className="flex flex-col items-center">
                <span className="prayer-name">{t("prayer." + prayer)}</span>
                <span
                  className="time"
                  style={{
                    borderRadius:
                      prayer === "Fajr"
                        ? i18n.language === Language.AR
                          ? "0px 10px 10px 0px"
                          : "10px 0px 0px 10px"
                        : prayer === "Isha"
                        ? i18n.language === Language.AR
                          ? "10px 0px 0px 10px"
                          : "0px 10px 10px 0px"
                        : "none",
                  }}
                >
                  {prayerTimes.times[prayer]}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default PrayerTimes;
