import "./MyGoogleMap.scss";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import {
  ControlPosition,
  Map,
  MapControl,
  Marker,
} from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import PinMarker from "../pinMarkers/PinMarkers.tsx";
import { setRadius } from "../../../features/mosque/mosqueSlice.ts";
import { useAppDispatch, useAppSelector } from "../../../app/hooks.ts";

const MyGoogleMap = ({
  center,
  listPositions,
  itenerary,
  myPosition,
  modeTransport,
}) => {
  const dispatch = useAppDispatch();
  const [map, setMap] = React.useState<any>(null);

  const selectedMosque = useAppSelector((state) => state.mosque.selectedMosque);
  const [marquerMosque, setMarquerMosque] = useState<any>(null);
  const radius = useAppSelector((state) => state.mosque.radiusGettingMosque);
  const [infoItineraire, setInfoItinaraire] = useState<any>(null);
  function renderMarkers(map, position, icon, title) {
    // const infowindow = new window.google.maps.InfoWindow({
    //   position: position,
    // });
    // infowindow.setContent(title);
    // infowindow.open(map);
    if (icon && marquerMosque) {
      marquerMosque.setMap(null);
    }
    const marker = new window.google.maps.Marker({
      position,
      map,
      title: title,
      icon,
    });
    if (icon) {
      setMarquerMosque(marker);
    }
  }
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
    renderMarkers(map, myPosition, null, "My position");
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (itenerary) {
      const DirectionsService = new window.google.maps.DirectionsService();

      DirectionsService.route(
        {
          origin: itenerary.from,
          destination: itenerary.to,
          travelMode: modeTransport,
          unitSystem: window.google.maps.UnitSystem.METRIC,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setInfoItinaraire(result);
            if (map) {
              renderMarkers(
                map,
                itenerary.to,
                "marker-mosque.svg",
                selectedMosque.name
              );
            }
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [itenerary, map]);

  const LIST_RADIUS = [
    { label: "1 Km", value: 1000, style: { insetInlineStart: "-4%" } },
    { label: "10 Km", value: 10000, style: { insetInlineStart: "5%" } },
    { label: "20 Km", value: 20000, style: { insetInlineStart: "15%" } },
    { label: "50 Km", value: 50000, style: { insetInlineStart: "45%" } },
    { label: "100 Km", value: 100000, style: { insetInlineEnd: "-4%" } },
  ];
  return (
    <div className="google-map">
      {center.lat != 0 && (
        <>
          {itenerary ? (
            // if a mosque selected
            <GoogleMap
              zoom={16}
              center={center}
              mapContainerStyle={{ height: "100%" }}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {infoItineraire !== null && (
                <DirectionsRenderer
                  // required
                  options={{
                    directions: infoItineraire,
                    draggable: false,
                    polylineOptions: {
                      strokeColor: "#237761",
                      strokeWeight: 5,
                    },
                    suppressMarkers: true,
                    markerOptions: {
                      // icon: icon,
                      // shape: { circle: "35.8235875,0.630928" },
                    },
                  }}
                />
              )}
            </GoogleMap>
          ) : (
            // if any mosque was selected
            <Map defaultZoom={16} defaultCenter={center} mapId="muslim-map">
              <MapControl position={ControlPosition.BOTTOM_RIGHT}>
                <div className="relative mb-4 mt-2" id="radius-range">
                  <label htmlFor="labels-range-input" className="sr-only">
                    Labels range
                  </label>
                  <input
                    id="labels-range-input"
                    type="range"
                    value={radius}
                    min="1000"
                    step="500"
                    max="100000"
                    className="w-full h-1 rounded-lg appearance-none cursor-pointer"
                  />
                  {LIST_RADIUS.map((el, index) => (
                    <div className="distances" key={index}>
                      <hr
                        onClick={(e) => dispatch(setRadius(el.value))}
                        style={el.style}
                        className={radius === el.value ? "selected-value" : ""}
                      />
                      <span
                        className={
                          "text-sm text-gray-500 dark:text-gray-400 absolute -top-6 cursor-pointer label-distance " +
                          (el.value === radius ? "label-visible" : "")
                        }
                        style={el.style}
                      >
                        {el.label}
                      </span>
                    </div>
                  ))}
                </div>
              </MapControl>
              <PinMarker
                pois={listPositions}
                centerCircle={myPosition}
              ></PinMarker>
              <Marker position={myPosition} title={"My position"}></Marker>
            </Map>
          )}
        </>
      )}
    </div>
  );
};

export default MyGoogleMap;
