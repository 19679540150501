import "../../index.scss";
import Footer from "../../components/layout/Footer.jsx";
import {
  PRAYERS_LIST,
  SPECIAL_PRAYERS_LIST,
} from "../../constants/salat.constants.ts";
import brownArrowImage from "../../assets/salahIcons/brown_arrow.svg";
import myIcon from "../../assets/salahIcons/MosqueBG.svg";
import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function DetailSalahPage() {
  const { prayer } = useParams();
  const pray = [...PRAYERS_LIST, ...SPECIAL_PRAYERS_LIST].find(
    (p) => p.name.toLowerCase() === prayer.toLowerCase()
  );
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={i18n.language === "ar" ? "icon-ar icon" : "icon"}
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">
          {t("learn.SALAT.TITLE_1") + " " + t(pray?.title)}
        </span>
      </div>

      <div className="relative flex-1 flex justify-center items-center">
        <div
          className={
            "flex max-w-6xl lg:space-x-16 w-full p-8 " +
            (!pray.steps
              ? " flex-col-reverse"
              : "flex-col lg:flex-row md:flex-row")
          }
        >
          {/* title2+paragraphe */}
          <div className="flex-1 justify-start m-5 mb-0">
            <h1 className="font-bold text-gray-800 mb-8">
              {t("learn.SALAT.LEARN")}
            </h1>
            <p
              className="text-justify text-gray-600"
              dangerouslySetInnerHTML={{ __html: t(pray.description) }}
            />
          </div>

          {/* Mosque SVG */}
          <div className="relative flex-1 flex justify-center items-center">
            <img
              className={
                "absolute mb-6 w-full max-w-[32em] min-w-[32em] h-auto " +
                (pray.steps ? "" : "mt-12")
              }
              src={myIcon}
              alt="My Icon"
            />

            {/*  Image */}
            <div className="relative flex flex-col items-center">
              <img
                src={pray.image}
                alt={t(pray.title)}
                className=" object-cover mt-32"
                style={{ width: "8em" }}
              />

              {/*  prayername */}
              <h2 className="text-3xl font-semibold text-gray-800 mt-8">
                {t(pray.title)}
              </h2>
              {pray.steps && (
                <Link to={"steps"}>
                  {/* Button */}
                  <button className="mt-7 ml-4 inline-flex items-center bg-backBrown bg-opacity-30 text-brown hover:bg-[#dbd5bb] border border-brown font-bold py-2 px-4 rounded-lg">
                    {t("learn.SALAT.RAKAA.TITLE")}
                    <img
                      src={brownArrowImage}
                      alt="rakaa"
                      className="mx-2 w-5 h-5"
                      style={{ scale: i18n.language === "ar" ? "-1" : "1" }}
                    />
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailSalahPage;
