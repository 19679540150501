import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ZakatPage.scss";
import { faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { LIST_POSSESSIONS_ZAKAT } from "../../constants/static_data.constants.ts";
import { useContext } from "react";
import { t } from "i18next";
import iconInformation from "../../assets/images/zakat/info.svg";
import { openDialog } from "../../features/general/generalSlice.ts";
import { useAppDispatch, useAppSelector } from "../../app/hooks.ts";
import Dialog from "../../components/common/dialog/Dialog.tsx";
import axios from "axios";
import { GET_PARAM_BY_NAME } from "../../constants/apis.constants.ts";
import Footer from "../../components/layout/Footer.jsx";
import imageMoney from "../../assets/images/zakat/money.svg";

const ZakatContext = createContext();

const ZakatPage = () => {
  const { t, i18n } = useTranslation();
  const [numberSteps, setNumberSteps] = useState(0);
  const [step, setStep] = useState(0);
  const [listPossessionsChecked, setListPossessionsChecked] = useState([]);
  const dialog = useAppSelector((state) => state.generalSlice);
  const [nisabMoney, setNisabMoney] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);

  const handleNextStep = () => {
    if (step === 0) {
      if (!listPossessionsChecked.length) {
        toast.error(t("alert.POSSESSION_REQUIRED"));
      } else {
        setStep((currentStep) => currentStep + 1);
      }
    } else {
      let filledAmout = false;

      for (let j = 0; j < listPossessionsChecked[step - 1].list.length; j++) {
        if (listPossessionsChecked[step - 1].list[j].amount !== 0) {
          filledAmout = true;
          break;
        }
      }
      if (!filledAmout) {
        return toast.error(t("alert.ONE_REQUIRED"));
      }
      setStep((currentStep) => currentStep + 1);
    }
  };

  useEffect(() => {
    // Fetch the nisab value from the API
    axios
      .get(`${GET_PARAM_BY_NAME}nisab`)
      .then((response) => {
        setNisabMoney(response.data.data[0].value);
      })
      .catch((_error) => {
        toast.error(t("alert.ECHEC_SERVER"));
      });
    // set screen width
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  return (
    <ZakatContext.Provider
      value={{
        listPossessionsChecked,
        setListPossessionsChecked,
        setNumberSteps,
        setStep,
        nisabMoney,
        screenWidth,
      }}
    >
      {dialog.dialogOpened && dialog.nameDialog === "information-zakat" ? (
        <Dialog
          title="alert.ADD_ACCESS"
          description={"alert.POSITION_REQUIRED"}
          secondaryButton={{ title: t("common.OK") }}
        ></Dialog>
      ) : (
        <div className="pages-position">
          <div className="pages-header">
            <span className="title-page">{t("zakat.TITLE")}</span>
          </div>
          <div className="zakat-calculator-body">
            <div
              className="w-full p-4 min-700-screen:p-8 flex justify-center"
              style={{ minHeight: "50vh" }}
            >
              {step === 0 && <StepZero></StepZero>}
              {step > 0 && step <= numberSteps && (
                <StepPossession
                  possession={listPossessionsChecked[step - 1]}
                ></StepPossession>
              )}

              {step === numberSteps + 1 && <Result></Result>}
            </div>

            <div className="w-full flex justify-between">
              {step !== 0 ? (
                <div
                  className="cursor-pointer flex items-center"
                  onClick={() => setStep((currentStep) => currentStep - 1)}
                >
                  <FontAwesomeIcon
                    icon={i18n.language === "ar" ? faAnglesRight : faAnglesLeft}
                    className="icon"
                  />
                  <span className="text-base xl:text-xl md:text-lg text-green font-bold">
                    {t("common.PREVIOUS")}
                  </span>
                </div>
              ) : (
                <div></div>
              )}
              {step <= numberSteps ? (
                <div
                  className="cursor-pointer flex items-center"
                  onClick={handleNextStep}
                >
                  <span className="text-base xl:text-xl md:text-lg text-green font-bold">
                    {step === 0
                      ? t("zakat.START")
                      : step === numberSteps
                      ? t("zakat.DONE")
                      : t("common.NEXT")}
                  </span>
                  <FontAwesomeIcon
                    icon={i18n.language === "ar" ? faAnglesLeft : faAnglesRight}
                    className="icon"
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>

            {/* stepper bar */}
            <div className="flex flex-col items-center">
              <span className="mb-2 font-bold">
                {t("common.STEP", {
                  step: step > numberSteps ? numberSteps : step,
                  totalSteps: numberSteps,
                })}
              </span>
              <input
                type="range"
                value={
                  numberSteps
                    ? step > numberSteps
                      ? 100
                      : (step / numberSteps) * 100
                    : 0
                }
                className={
                  "rounded-lg overflow-hidden appearance-none h-3 w-128 bar-stepper " +
                  (i18n.language === "ar" ? "arabic-bar" : "")
                }
              />
            </div>
          </div>
          <Footer></Footer>
        </div>
      )}
    </ZakatContext.Provider>
  );
};

const StepZero = () => {
  const dispatch = useAppDispatch();

  const zakatContext = useContext(ZakatContext);
  const handleCheckPossession = (event, possession) => {
    const checked = event.target.checked;

    if (checked) {
      zakatContext.setNumberSteps((currentNumberStep) => currentNumberStep + 1);
      zakatContext.setListPossessionsChecked((currentList) =>
        currentList.concat([
          { ...possession, list: possession.list.map((el) => ({ ...el })) },
        ])
      );
    } else {
      zakatContext.setNumberSteps((currentNumberStep) => currentNumberStep - 1);
      zakatContext.setListPossessionsChecked((currentList) =>
        currentList.filter((el) => el.value !== possession.value)
      );
    }
  };

  const compareCheck = (list, el) => {
    return list.find((x) => x.value === el.value);
  };
  const viewDescription = (element) => {
    dispatch(
      openDialog({
        nameDialog: "information-zakat",
        description: element.description,
        title: element.name,
        image: element.image,
      })
    );
  };

  return (
    <div className="">
      {/* list possessions */}
      <div className="mb-8 flex flex-col items-center">
        <h1 className="mb-4">{t("zakat.I_HAVE")}</h1>
        <div className="px-2 min-700-screen:px-8">
          <ul className="font-medium rounded-lg">
            {LIST_POSSESSIONS_ZAKAT.map((el, index) => (
              <li className="" key={index}>
                <div className="flex items-center ps-3 justify-between">
                  <input
                    id={el.value}
                    type="checkbox"
                    value={el.value}
                    className="w-6 h-6 text-green bg-green border-green rounded focus:ring-green focus:ring-2 cursor-pointer accent-green"
                    onChange={(event) => handleCheckPossession(event, el)}
                    checked={compareCheck(
                      zakatContext.listPossessionsChecked,
                      el
                    )}
                  />
                  <label
                    htmlFor={el.value}
                    className="py-3 mx-4 font-medium cursor-pointer w-40"
                  >
                    {t(el.name)}
                  </label>
                  <img
                    src={iconInformation}
                    alt=""
                    className="icon-information"
                    onClick={() => viewDescription(el)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const StepPossession = ({ possession }) => {
  const zakatContext = useContext(ZakatContext);
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  const changeValuePossession = (index, event) => {
    zakatContext.setListPossessionsChecked((Possessions) => {
      const possessionsUpdated = [...Possessions];

      const posessionChecked = possessionsUpdated.find(
        (el) => el.name === possession.name
      );
      if (posessionChecked.list[index]) {
        posessionChecked.list[index].amount = Number(event.target.value);
      }
      return possessionsUpdated;
    });
  };
  const viewDescription = (element) => {
    dispatch(
      openDialog({
        nameDialog: "information-zakat",
        description: element.description,
        title: element.name,
        image: element.image,
      })
    );
  };

  const handleTypeAgriculture = (indexAgriculture, type) => {
    zakatContext.setListPossessionsChecked((Possessions) => {
      const agriculture = Possessions.find((el) => el.value === "agriculture");

      const possessionsUpdated = [...Possessions];
      if (agriculture) {
        agriculture.list[indexAgriculture].type = type;
      }
      return possessionsUpdated;
    });
  };

  const TypeAgriculture = ({ agriculturePossession, index }) => {
    return (
      <div className="flex mx-2 justify-center">
        <div className="flex items-center me-4">
          <input
            id={"Irriguée" + agriculturePossession.value}
            type="radio"
            value="irrigated"
            name={"inline-radio-group" + agriculturePossession.value}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 cursor-pointer"
            onChange={() => handleTypeAgriculture(index, "irrigated")}
            checked={agriculturePossession.type === "irrigated"}
          />
          <label
            for={"Irriguée" + agriculturePossession.value}
            className="ms-2 text-sm font-medium text-gray-900 cursor-pointer"
          >
            {t("zakat.IRRIGATED")}
          </label>
        </div>
        <div className="flex items-center me-4">
          <input
            id={"Pluviale" + agriculturePossession.value}
            type="radio"
            value="pluviale"
            name={"inline-radio-group" + agriculturePossession.value}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 cursor-pointer"
            onChange={() => handleTypeAgriculture(index, "pluviale")}
            checked={agriculturePossession.type === "pluviale"}
          />
          <label
            for={"Pluviale" + agriculturePossession.value}
            className="ms-2 text-sm font-medium text-gray-900 cursor-pointer"
          >
            {t("zakat.PLUVIALE")}
          </label>
        </div>
      </div>
    );
  };

  return (
    <div className="zakat-possession">
      <div className="mb-8">
        <h1 className="mb-2">{t(possession.name)}</h1>
        <div className="min-700-screen:px-8 min-700-screen:w-1/2">
          {possession.list.map((el, index) => (
            <div className="mb-2 min-700-screen:mb-4" key={index}>
              <h2>{t(el.name)}</h2>
              <div className="flex possession items-center">
                {el.image && (
                  <img
                    src={el.image}
                    alt=""
                    className={
                      i18n.language === "ar"
                        ? "arab-icon-possession"
                        : "icon-possession"
                    }
                  />
                )}

                <span className="inline-flex items-center px-2 text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                  {t(possession?.unit)}
                </span>
                <input
                  style={
                    el.value === "or"
                      ? {
                          width: zakatContext.screenWidth > 450 ? "6em" : "4em",
                        }
                      : {}
                  }
                  type="number"
                  min="0"
                  id={el.value}
                  className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-green focus:border-green block flex-1 text-sm border-gray-300 p-2 min-700-screen:p-2.5"
                  placeholder=""
                  value={el.amount}
                  onChange={(e) => changeValuePossession(index, e)}
                />

                {el.value === "or" && (
                  <div className="flex flex-row karat">
                    <span
                      style={{ marginInline: "0.5em" }}
                      className="flex items-center"
                    >
                      {t("zakat.KARAT")}
                    </span>
                    <input
                      style={{ width: "3em" }}
                      type="number"
                      placeholder="24"
                      className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-green focus:border-green block flex-1 text-sm border-gray-300 p-2 min-700-screen:p-2.5"
                    />
                  </div>
                )}

                <img
                  src={iconInformation}
                  alt=""
                  className="icon-information"
                  onClick={() => viewDescription(el)}
                />

                {/* pluviale / irrigated */}
                {possession.value === "agriculture" &&
                  zakatContext.screenWidth > 700 && (
                    <TypeAgriculture agriculturePossession={el} index={index} />
                  )}
              </div>
              {/* pluviale / irrigated */}
              {possession.value === "agriculture" &&
                zakatContext.screenWidth <= 700 && (
                  <TypeAgriculture agriculturePossession={el} index={index} />
                )}
            </div>
          ))}
        </div>
      </div>
      <div>
        {possession.value === "cache" && (
          <img src={possession.image} alt="" className="image-money" />
        )}
      </div>
    </div>
  );
};

const Result = () => {
  const zakatContext = useContext(ZakatContext);
  const { t } = useTranslation();
  const [possessionWithZakat, setPossessionWithZakat] = useState([]);

  const downloadZakatCalculator = () => {};

  const resetCalculator = () => {
    zakatContext.setNumberSteps(0);
    zakatContext.setStep(0);
    zakatContext.setListPossessionsChecked([]);
  };

  /******* calculate nisab ****/
  useEffect(() => {
    let moneyValue = 0;
    for (let possession of zakatContext.listPossessionsChecked) {
      possession.list.forEach((el) => {
        if (possession.value === "or_argent" && el.amount) {
          // calculate nisab or and silver
          setPossessionWithZakat((current) => [
            ...current,
            {
              name: el.name,
              value: `${el.amount} ${t("zakat.G")}`,
              nisab: `${el.nisab} ${t("zakat.G")}`,
              zakat: `${
                el.amount >= el.nisab ? (el.amount * 0.025).toFixed(3) : 0
              } ${t("zakat.G")}`,
              image: el.image,
            },
          ]);
        } else if (possession.value === "cache") {
          moneyValue = moneyValue + el.amount;
        } else if (possession.value === "livestock" && el.amount) {
          // calculate nisab livestock
          if (el.value === "cattle") {
            let zakat = 0;
            if (el.amount >= 30) {
              if (el.amount < 60) {
                zakat = t("zakat.VACHE.VEAU", { nombre: 1 });
              } else if (el.amount < 70) {
                zakat = t("zakat.VACHE.VEAUX", { nombre: 2 });
              } else if (el.amount < 80) {
                zakat = `${t("zakat.VACHE.VEAU", { nombre: 1 })} + ${t(
                  "zakat.VACHE.VACHE_1",
                  { nombre: 1 }
                )}`;
              } else if (el.amount < 90) {
                zakat = `${t("zakat.VACHE.VEAU", { nombre: 2 })} + ${t(
                  "zakat.VACHE.VACHE_1",
                  { nombre: 1 }
                )}`;
              } else {
                if (el.amount % 30 < 10) {
                  zakat = `${t("zakat.VACHE.VEAUX", {
                    nombre: Math.trunc(el.amount / 30),
                  })}`;
                } else if ((el.amount - 40) % 30 < 10) {
                  zakat = `${t("zakat.VACHE.VEAU", {
                    nombre: Math.trunc((el.amount - 40) / 30),
                  })} + ${t("zakat.VACHE.VACHE_1", { nombre: 1 })}`;
                } else {
                  zakat = `${t("zakat.VACHE.VEAU", {
                    nombre: Math.trunc((el.amount - 80) / 30),
                  })} + ${t("zakat.VACHE.VACHE_1", { nombre: 2 })}`;
                }
              }
            }
            setPossessionWithZakat((current) => [
              ...current,
              {
                name: el.name,
                value: el.amount,
                nisab: `${el.nisab}`,
                zakat: zakat,
                image: el.image,
              },
            ]);
          } else if (el.value === "sheep") {
            let zakat = 0;
            if (el.amount >= 40) {
              if (el.amount < 121) {
                zakat = t("zakat.MOUTON.MOUTON_1", { nombre: 1 });
              } else if (el.amount < 201) {
                zakat = t("zakat.MOUTON.MOUTON_1", { nombre: 2 });
              } else if (el.amount < 400) {
                zakat = t("zakat.MOUTON.MOUTON_2", { nombre: 3 });
              } else {
                zakat = `${t("zakat.MOUTON.MOUTON_2", {
                  nombre: Math.trunc(el.amount / 100),
                })}`;
              }
            }
            setPossessionWithZakat((current) => [
              ...current,
              {
                name: el.name,
                value: el.amount,
                nisab: `${el.nisab}`,
                zakat: zakat,
                image: el.image,
              },
            ]);
          } else if (el.value === "camel") {
            let zakat = "0";
            let customFontSize = "0.9em";
            if (el.amount >= el.nisab) {
              if (el.amount < 25) {
                zakat = `${t("zakat.MOUTON.MOUTON_1", {
                  nombre: Math.trunc(el.amount / 5),
                })}`;
              } else if (el.amount < 36) {
                zakat = t("zakat.CHAMEAU_1_2");
              } else if (el.amount < 46) {
                zakat = t("zakat.CHAMEAU.CHAMEAU_2_3", { nombre: 1 });
              } else if (el.amount < 76) {
                zakat = t("zakat.CHAMEAU.CHAMEAU_4_5", { nombre: 1 });
              } else if (el.amount < 91) {
                zakat = t("zakat.CHAMEAU.CHAMEAU_2_3", { nombre: 2 });
              } else if (el.amount < 121) {
                zakat = t("zakat.CHAMEAU.CHAMEAU_3_4", { nombre: 2 });
              } else if (el.amount < 130) {
                customFontSize = "0.73em";
                zakat = `${t("zakat.CHAMEAU.CHAMEAU_2_3", { nombre: 3 })} ${t(
                  "common.OR"
                )} ${t("zakat.CHAMEAU.CHAMEAU_3_4", { nombre: 2 })}`;
              } else {
                if (el.amount % 40 < 10) {
                  zakat = `${t("zakat.CHAMEAU.CHAMEAU_2_3", {
                    nombre: Math.trunc(el.amount / 40),
                  })}`;
                } else if ((el.amount - 50) % 40 < 10) {
                  customFontSize = "0.73em";

                  zakat = `${t("zakat.CHAMEAU.CHAMEAU_2_3", {
                    nombre: Math.trunc((el.amount - 50) / 40),
                  })} + ${t("zakat.CHAMEAU.CHAMEAU_3_4", { nombre: 1 })}`;
                } else if ((el.amount - 100) % 40 < 10) {
                  customFontSize = "0.73em";

                  zakat = `${t("zakat.CHAMEAU.CHAMEAU_2_3", {
                    nombre: Math.trunc((el.amount - 100) / 40),
                  })} + ${t("zakat.CHAMEAU.CHAMEAU_3_4", { nombre: 2 })}`;
                } else if ((el.amount - 150) % 40 < 10) {
                  customFontSize = "0.73em";

                  zakat = `${t("zakat.CHAMEAU.CHAMEAU_2_3", {
                    nombre: Math.trunc((el.amount - 150) / 40),
                  })} + ${t("zakat.CHAMEAU.CHAMEAU_3_4", { nombre: 3 })}`;
                } else {
                  customFontSize = "0.73em";

                  zakat = `${t("zakat.CHAMEAU.CHAMEAU_2_3", {
                    nombre: Math.trunc((el.amount - 200) / 40),
                  })} + ${t("zakat.CHAMEAU.CHAMEAU_3_4", { nombre: 4 })}`;
                }
              }
            }

            setPossessionWithZakat((current) => [
              ...current,
              {
                name: el.name,
                value: el.amount,
                nisab: `${el.nisab}`,
                zakat: zakat,
                image: el.image,
                fontSize: customFontSize,
              },
            ]);
          }
        } else if (possession.value === "agriculture" && el.amount) {
          // calculate nisab agriculture
          setPossessionWithZakat((current) => [
            ...current,
            {
              name: el.name,
              value: `${el.amount} ${t("zakat.KG")}`,
              nisab: `${el.nisab} ${t("zakat.KG")}`,
              zakat:
                el.amount >= el.nisab
                  ? `${(
                      el.amount * (el.type === "pluviale" ? 0.1 : 0.05)
                    ).toFixed(3)} ${t("zakat.KG")}`
                  : `0 ${t("zakat.KG")}`,
              image: el.image,
            },
          ]);
        }
      });
    }
    // calculate nisab money
    if (moneyValue) {
      setPossessionWithZakat((current) => [
        ...current,
        {
          name: "zakat.MONEY",
          value: `${moneyValue} ${t("zakat.TND")}`,
          nisab: `${zakatContext.nisabMoney} ${t("zakat.TND")}`,
          zakat: `${
            moneyValue >= zakatContext.nisabMoney
              ? (moneyValue * 0.025).toFixed(3)
              : 0
          } ${t("zakat.TND")}`,
          image: imageMoney,
        },
      ]);
    }
  }, []);

  return (
    <div className="">
      <div className="mb-8">
        <h1 className="mb-2">{t("zakat.RESULT")}</h1>
        <div className="">
          <div className="result-container">
            <div className="flex flex-wrap justify-center">
              {possessionWithZakat?.map((el, index) => (
                <CardCalculator possession={el} key={index} />
              ))}
            </div>
            <div className="flex flex-row justify-evenly buttons">
              <button className="reset-btn" onClick={resetCalculator}>
                {t("zakat.RECALCULATE")}
              </button>
              {/* <button
                  className="download-btn"
                  onClick={downloadZakatCalculator}
                >
                  Télécharger
                </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CardCalculator = ({ possession }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="result-section">
      <div className="flex">
        <img src={possession.image} alt="" />
        <h2>{t(possession.name)}</h2>
      </div>
      <div className="flex items-center justify-between">
        <h3>{t("zakat.I_HAVE")}</h3>
        <span>{possession.value}</span>
      </div>

      <div className="flex items-center justify-between text-redError font-semibold">
        <h3>{t("zakat.NISAB")}</h3>
        <span>{possession.nisab}</span>
      </div>

      <div
        className={
          "flex items-center justify-between " +
          (i18n.language === "ar" ? "result-ar" : "result")
        }
      >
        <h1>{t("zakat.ZAKAT")}</h1>
        <span className="value-zakat" style={{ fontSize: possession.fontSize }}>
          {possession.zakat}
        </span>
      </div>
    </div>
  );
};

export default ZakatPage;
