import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./GhouslSteps.scss";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../../../../components/layout/Footer";
import { GHOUSL_STEPS } from "../../../../constants/static_data.constants.ts";
import {
  faArrowLeft,
  faAnglesRight,
  faAnglesLeft,
} from "@fortawesome/free-solid-svg-icons";
import imageGhousl from "../../../../assets/images/wudhu/ghousl2.svg";

export default function GhouslSteps() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [stepGhousl, setStepWudhu] = useState(1);
  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={i18n.language === "ar" ? "icon-ar icon" : "icon"}
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">
          {t("learn.WUDHU_GHOUSL.GHOUSL_PAGE.TITLE")}
        </span>
      </div>
      <div className="ghousl-body">
        <StepGhousl stepGhousl={GHOUSL_STEPS[stepGhousl - 1]}></StepGhousl>
        <div className="flex items-center min-600-screen:mt-10 justify-between">
          {stepGhousl > 1 ? (
            <div
              onClick={() => setStepWudhu((currentStep) => currentStep - 1)}
              className="cursor-pointer flex items-center"
            >
              <FontAwesomeIcon
                icon={i18n.language === "ar" ? faAnglesRight : faAnglesLeft}
                className="icon text-green"
              />
              <span className="text-base xl:text-xl md:text-lg text-green font-bold">
                {t("common.PREVIOUS")}
              </span>
            </div>
          ) : (
            <div></div>
          )}
          {stepGhousl < GHOUSL_STEPS.length && (
            <div
              onClick={() => setStepWudhu((currentStep) => currentStep + 1)}
              className="cursor-pointer flex items-center"
            >
              <span className="text-base xl:text-xl md:text-lg text-green font-bold">
                {t("common.NEXT")}
              </span>
              <FontAwesomeIcon
                icon={i18n.language === "ar" ? faAnglesLeft : faAnglesRight}
                className="icon"
              />
            </div>
          )}
        </div>
        {/* stepper */}
        <div className="flex flex-col items-center stepper-ghousl">
          <span className="mb-2 font-bold">
            {t("common.STEP", {
              step: stepGhousl,
              totalSteps: GHOUSL_STEPS.length,
            })}
          </span>

          <input
            type="range"
            value={
              GHOUSL_STEPS.length
                ? stepGhousl > GHOUSL_STEPS.length
                  ? 100
                  : (stepGhousl / GHOUSL_STEPS.length) * 100
                : 0
            }
            className={
              "rounded-lg overflow-hidden appearance-none h-3 w-128 bar-stepper " +
              (i18n.language === "ar" ? "arabic-bar" : "")
            }
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

const StepGhousl = ({ stepGhousl }) => {
  const { t } = useTranslation();

  return (
    <div className="step-ghousl">
      <img src={imageGhousl} alt="" />
      <div className="step-ghousl-details">
        <h1>{t(stepGhousl.title)}</h1>
        <p>{t(stepGhousl.description)}</p>
      </div>
    </div>
  );
};
