import { Link } from "react-router-dom";
import "./WudhuGhouslPage.scss";
import { useTranslation } from "react-i18next";
import wudhuImage from "../../../assets/images/wudhu/wudhu.svg";
import ghouslImage from "../../../assets/images/wudhu/ghousl.svg";
import { faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../../../components/layout/Footer";

const WudhuGhouslPage = () => {
  const { t } = useTranslation();
  return (
    <div className="pages-position">
      <div className="pages-header">
        <span className="title-page"> {t("learn.WUDHU_GHOUSL.SUB_TITLE")}</span>
      </div>
      <div className="wudhu-ghousl-body">
        <div
          className="wudhu-description"
          dangerouslySetInnerHTML={{
            __html: t("learn.WUDHU_GHOUSL.DESCRIPTION"),
          }}
        />
        <div className="wudhu-select-type">
          <div>
            <Link to="ghousl">
              <img src={ghouslImage} alt="" className="ghousl-image" />
            </Link>
            <Link to="wudhu">
              <img src={wudhuImage} alt="" className="wudhu-image" />
            </Link>
          </div>
        </div>
        <div className="flex justify-center">
          <div
            className="flex items-center mt-10 justify-between"
            style={{ width: "30em", direction: "ltr" }}
          >
            <Link to="ghousl" className="icons">
              <FontAwesomeIcon
                icon={faAnglesLeft}
                className="text-green mx-2"
              />
              <span className="font-bold text-green">
                {t("learn.WUDHU_GHOUSL.GHOUSL")}
              </span>
            </Link>
            <Link to="wudhu" className="icons">
              <span className="font-bold text-golden">
                {t("learn.WUDHU_GHOUSL.WUDHU")}
              </span>
              <FontAwesomeIcon
                icon={faAnglesRight}
                className="text-golden mx-2"
              />
            </Link>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default WudhuGhouslPage;
